body {
    font-family: "Montserrat", sans-serif;
}

.pulser {
    animation: pulser-animation infinite 2s linear;
}

.spinner {
    animation: spinner-animation infinite 2s linear;
}

@keyframes pulser-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
